












import { Vue, Component, Ref, Prop } from 'vue-property-decorator';
@Component({})
export default class VideoPlayer extends Vue {
  @Ref(`videoPlayer`) readonly videoPlayer!: HTMLVideoElement;

  @Prop({ required: true })
  thumbnail!: string;

  @Prop({ required: true })
  videoSource!: string;
}
