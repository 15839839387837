

























































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { LectureItem } from '@/models/lectures';
import EmptyPlaceholder from '@/components/common/EmptyPlaceholder.vue';
@Component({
  components: { EmptyPlaceholder },
})
export default class LectureTable extends Vue {
  @Prop({ required: true })
  data!: LectureItem[];

  get lectures() {
    return this.data;
  }

  showDiscount(payload: LectureItem) {
    return Number(payload.discountPrice) > 0;
  }

  @Emit(`video`)
  showVideo(payload: LectureItem) {
    return payload;
  }

  @Emit(`price`)
  editPrice(payload: LectureItem) {
    return payload;
  }

  @Emit(`availability`)
  updateAvailability(payload: LectureItem) {
    return payload;
  }

  @Emit(`delete`)
  deleteItem(item: LectureItem) {
    return item;
  }

  @Emit(`edit`)
  editItem(item: LectureItem) {
    return item;
  }

  formatPrice(payload: string) {
    return payload ? `RM ${payload}` : `Not set`;
  }
}
