









































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import ManagementLayout from '@/layouts/management/ManagementLayout.vue';
import { FolderItem } from '@/models/miscellaneous';
import SearchBar from '@/components/common/SearchBar.vue';
import ManagementList from '@/components/management/ManagementList.vue';
import { CourseListItem, SubjectLevelListItem } from '@/models/management';
import { CourseStores } from '@/store/course';
import moment from 'moment';
import LectureTable from '@/components/resources/LectureTable.vue';
import { LectureStores } from '@/store/lectures';
import { FetchLectureParams, LectureItem } from '@/models/lectures';
import VideoPlayer from '@/components/common/VideoPlayer.vue';
import ModalFormLayout from '@/layouts/common/ModalFormLayout.vue';
import SplitInput from '@/components/common/SplitInput.vue';
import { formatSecondsToVideoDuration } from '@/utils/helper';
import { PaginationDetails } from '@/models/generic';
import { FetchCoursesWithParams } from '@/models/courses';
import { FormStores } from '@/store/forms/index';
import { FormItemSearchParams } from '@/models/forms';

@Component({
    components: { 
        Page, 
        ManagementLayout, 
        SearchBar, 
        ManagementList, 
        LectureTable,
        VideoPlayer,
        ModalFormLayout,
        SplitInput
    },
})
export default class Lectures extends Vue {
    // UI states
    currentFolders: FolderItem[] = [];
    currentCourses: CourseListItem[] = [];
    currentLectures: LectureItem[] = [];
    currentForms: SubjectLevelListItem[] = [];
    isVideoModalShown = false;
    currentVideoThumbnail = ``;
    currentVideoSource = ``;
    isPriceModalShown = false;
    currentPriceLecture: LectureItem = {
        id: ``,
        thumbnail: ``,
        name: ``,
        uploadDate: ``,
        duration: ``,
        isAvailable: false,
        price: ``,
        video: ``,
        discountPrice: ``,
    };
    selectedLecturePrice = ``;
    selectedLectureDiscountedPrice = ``;
    selectedCourseId = 0;
    //Pagination
    currentPage = 1;
    currentLimit = 10;
    currentKeyword = ``;
    currentTotalCount = 10;
    isPaginationReset = false;
    isPageSpecified = false;
    // Stores
    courseListStore = CourseStores.list;
    lectureListStore = LectureStores.list;
    lectureDetailStore = LectureStores.detail;
    lectureStateStore = LectureStores.state;
    formCreateStore = FormStores.create;
    formDetailStore = FormStores.details;
    formListStore = FormStores.list;

    get folders() {
        return this.currentFolders;
    }

    get courses() {
        return this.currentCourses;
    }
    
    get forms() {
        return this.currentForms;
    }

    get isCurrentRouteActive() {
        return this.$route.path === `/resources/lectures`;
    }

    get lectures() {
        return this.currentLectures;
    }

    get videoSrc() {
        return this.currentVideoSource;
    }

    get videoThumbnail() {
        return this.currentVideoThumbnail;
    }

    get currentPriceData() {
        return this.currentPriceLecture;
    }

    get isSaveButtonDisabled() {
        return this.selectedLecturePrice.length === 0 || (Number(this.selectedLectureDiscountedPrice) > Number(this.selectedLecturePrice));
    }

    mounted() {
        if (this.lectureStateStore.lectureFolderState.length > 0 && this.lectureStateStore.isModified ) {
            this.currentFolders = this.lectureStateStore.lectureFolderState;
            this.lectureStateStore.updateLectureIsModified(false);
            switch(this.currentFolders.length) {
                case 2: {
                    this.currentCourses = this.lectureStateStore.courseData;
                    this.currentPage = this.lectureStateStore.currentPageState[this.currentFolders.length];
                    this.currentLimit = this.lectureStateStore.currentLimitState[this.currentFolders.length];
                    this.currentTotalCount = this.lectureStateStore.currentTotalCountState[this.currentFolders.length];
                    this.currentKeyword = this.lectureStateStore.currentKeywordState[this.currentFolders.length];
                    this.isPageSpecified = true;
                    break;
                }
                default: {
                    break;
                }
            }
            this.retrieveData();
        } else if ( this.lectureStateStore.lectureFolderState.length > 0 && !this.lectureStateStore.isModified ) {
            // Get back previous state
            this.currentFolders = this.lectureStateStore.lectureFolderState;
            this.currentCourses = this.lectureStateStore.courseData;
            this.currentLectures = this.lectureStateStore.lectureData;
            this.currentPage = this.lectureStateStore.currentPageState[this.currentFolders.length];
            this.currentLimit = this.lectureStateStore.currentLimitState[this.currentFolders.length];
            this.currentTotalCount = this.lectureStateStore.currentTotalCountState[this.currentFolders.length];
            this.currentKeyword = this.lectureStateStore.currentKeywordState[this.currentFolders.length];
            this.isPageSpecified = true;
        } else {
            this.currentFolders.push({ id: -1, name: `Courses` });
            this.retrieveData();
        }
    }

    // Search 
    searchItems(payload: string) {
        this.currentPage = 1;
        this.currentKeyword = payload;
        this.isPaginationReset = true;
        this.retrieveData();
    }

    //Pagination
    updatePagination(payload: PaginationDetails) {
        this.currentPage = payload.page;
        this.currentLimit = payload.limit;
        this.retrieveData();
    }

    resetPaginationStatus(payload: boolean) {
        this.isPaginationReset = payload;
    }

    resetCurrentPageStatus(payload: boolean) {
        this.isPageSpecified = payload;
    }

    retrieveData() {
        switch(this.currentFolders.length) {
            case 1: {
                // Base State
                const payload: Partial<FetchCoursesWithParams> = {
                    page: this.currentPage,
                    limit: this.currentLimit,
                };
                if (this.currentKeyword.length > 0) {
                    payload.keyword = this.currentKeyword;
                }
                this.courseListStore.retrieveCoursesWithParams(payload);
                break;
            }
            case 2: {
                this.selectedCourseId = this.currentFolders[this.currentFolders.length - 1].id;
                const payload: Partial<FetchLectureParams> = {
                    page: this.currentPage,
                    limit: this.currentLimit,
                    courseId: this.selectedCourseId,
                };
                if (this.currentKeyword.length > 0) {
                    payload.keyword = this.currentKeyword;
                }
                this.lectureListStore.retrieveLectures(payload);
                break;
            }
            case 3: {
                // Containing Subjects and Subject Name. Fetch Subjects and levels
                const payload: Partial<FormItemSearchParams> = {
                    subjectid: this.currentFolders[this.currentFolders.length - 1].id
                };
                this.formListStore.retrieveForms(payload);
                break;
            }
            default: {
                break;
            }
        }
    }

    resetFolders(payload: FolderItem[]) {
        // When Folders are reset, we need to readjust back to its original state
        this.currentFolders = payload;
        this.currentPage = this.lectureStateStore.currentPageState[this.currentFolders.length];
        this.currentLimit = this.lectureStateStore.currentLimitState[this.currentFolders.length];
        this.currentKeyword = this.lectureStateStore.currentKeywordState[this.currentFolders.length];
        this.isPageSpecified = true;
        this.saveState();
        this.retrieveData();
    }

    // Watch / Parse Function
    @Watch(`courseListStore.response`)
    parseCourseData() {
        if (this.courseListStore.response) {
            this.currentCourses = [];
            this.currentTotalCount = this.courseListStore.response.totalCount;
            this.courseListStore.response.objects.forEach((data) => {
                this.currentCourses.push({
                    id: data.id,
                    courseId: data.courseId,
                    name: `${data.courseName} - ${data.form.name} - ${moment(data.courseStartTime).format(`YYYY`)} - ${data.teacher.firstname} ${data.teacher.lastname}`,
                    numberOfCourses: -1,
                });
            });
            this.lectureStateStore.updateLectureCourseState(this.currentCourses);
        }
    }

    @Watch(`lectureListStore.response`)
    parseLectureData() {
        if (this.lectureListStore.response) {
            this.currentLectures = [];
            this.currentTotalCount = this.lectureListStore.response.totalCount;
            this.lectureListStore.response.objects.forEach((data) => {
                this.currentLectures.push({
                    id: data.id,
                    thumbnail: data.thumbnail_url,
                    video: data.recording_url,
                    name: `${this.currentFolders[this.currentFolders.length - 1].name}`,
                    isAvailable: data.availability,
                    uploadDate: ``,
                    duration: ``,
                    price: data.price,
                    discountPrice: data.discountPrice,
                });
            });
            this.lectureStateStore.updateLectureItemState(this.currentLectures);
        }
    }

    @Watch(`formListStore.response`)
    setForms() {
        if (this.formListStore.response) {
            this.currentForms = [];
            this.currentTotalCount = 0;
            this.formListStore.response.objects.forEach((data) => {
                this.currentForms.push({
                    id: data.id,
                    name: data.formName,
                    numberOfCourses: data.numberOfCourses,
                });
            });
            this.lectureStateStore.updateLectureFormState(this.currentForms);
        }
    }

    @Watch(`formCreateStore.response`)
    @Watch(`formDetailStore.response`)
    updateCurrentFormList() {
        /*
        Create Response contains data
        Update / Delete Response does not contain data
        */
        if (this.formCreateStore.response || this.formDetailStore.response) {
        this.formListStore.retrieveForms({
            page: this.currentPage,
            limit: this.currentLimit,
            subjectid: this.currentFolders[this.currentFolders.length - 1].id
        });
        }
    }

    // Navigation Functions
    redirectToResources() {
        this.saveState();
        this.$router.push({
            path: `/resources`
        });
    }

    navigateToCreateLectures() {
        this.saveState();
        this.$router.push({
            path: `/resources/lectures/create`
        });
    }

    // Folder Functions
    addFolderRouteForCourses(data: CourseListItem) {
        this.saveState();
        this.currentFolders.push({
            id: data.id,
            name: data.name,
        });
        this.currentKeyword = ``;
        this.currentPage = 1;
        this.isPaginationReset = true;
        this.selectedCourseId = data.id;
        this.retrieveData();
    }

    addFolderRouteForForms(data: CourseListItem) {
        this.saveState();
        this.currentFolders.push({
            id: data.id,
            name: data.name,
        });
        this.currentKeyword = ``;
        this.currentPage = 1;
        this.isPaginationReset = true;
        this.selectedCourseId = data.id;
        this.retrieveData();
    }

    // Modal Functions
    showVideo(payload: LectureItem) {
        this.isVideoModalShown = true;
        this.currentVideoSource= payload.video;
        this.currentVideoThumbnail = payload.thumbnail;
    }

    editPrice(payload: LectureItem) {
        this.isPriceModalShown = true;
        this.selectedLecturePrice = payload.price ? payload.price : ``;
        this.selectedLectureDiscountedPrice = payload.discountPrice ? payload.discountPrice : ``;
        this.currentPriceLecture = payload;
    }

    deleteLecture(payload: LectureItem) {
        // TODO:
    }

    editLecture(payload: LectureItem) {
        this.$router.push({
            path: `/resources/lectures/${payload.id}/edit`,
        });
    }

    //Update Functions
    updateLecturePrice(data: string) {
        this.selectedLecturePrice = data;
    }

    updateLectureDiscountedPrice(data: string) {
        this.selectedLectureDiscountedPrice = data;
    }

    updateLectureAvailability(payload: LectureItem) {
        this.currentPriceLecture = payload;
        this.lectureDetailStore.updateLecture({
            recordedId: this.currentPriceLecture.id,
            price: Number(payload.price),
            courseId: this.selectedCourseId,
            discount: Number(payload.discountPrice),
            available: payload.isAvailable,
        });
    }

    saveLecturePrice() {
        this.isPriceModalShown = false;
        this.lectureDetailStore.updateLecture({
            recordedId: this.currentPriceLecture.id,
            price: Number(this.selectedLecturePrice),
            courseId: this.selectedCourseId,
            discount: Number(this.selectedLectureDiscountedPrice),
            available: this.currentPriceData.isAvailable !== null && this.currentPriceData.isAvailable !== undefined ? this.currentPriceData.isAvailable : false,
        });
    }

    @Watch(`lectureDetailStore.response`)
    refetchList() {
        if (this.lectureDetailStore.response) {
            this.retrieveData();
        }
    }

    saveState() {
        this.lectureStateStore.updateLectureFolderState(this.currentFolders);
        this.lectureStateStore.updateLectureCourseState(this.currentCourses);
        this.lectureStateStore.updateLectureFormState(this.currentForms);
        this.lectureStateStore.updateLectureItemState(this.currentLectures);
        this.lectureStateStore.updateLectureCurrentPage({ folderLevel: this.folders.length, value: this.currentPage});
        this.lectureStateStore.updateLectureCurrentLimit({ folderLevel: this.folders.length, value: this.currentLimit});
        this.lectureStateStore.updateLectureCurrentTotalCount({ folderLevel: this.folders.length, value: this.currentTotalCount});
        this.lectureStateStore.updateLectureCurrentKeyword({ folderLevel: this.folders.length, value: this.currentKeyword});
    }

}
